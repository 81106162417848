export const MENU_ROUTE = {
  DASHBOARD: "/dashboard",
  NEWS: "/news",
  TRAINING_CENTRE: "/training-centre",
  ACTIVITY_FEED: `/training-centre/activity-feed`,
  BOOKMARKS: "/bookmarks",
  CASE_STUDIES: "/case-studies",
  TOPIC_GLOSSARY: "/topic-glossary",
  FEEDS: "/feeds",
  NEWSLETTER_TEMPLATES: "/newsletter-templates",
  NOTIFICATION_CENTRE: "/notification-centre",
  ONBOARDING: "/onboarding",
  SEARCH: "/search",
};
