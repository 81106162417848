import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { MENU_ROUTE } from "../utils/routes";

const Home: NextPage = () => {
  const router = useRouter();
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      router
        .push("/login")
        .catch((e) => console.error(e, "private route error"));
    } else {
      router
        .push(MENU_ROUTE.DASHBOARD)
        .catch((e) => console.error(e, "private route error"));
    }
  }, [router]);
  return null;
};

export default Home;
